.conference-header {
    border: 0.2px solid black;
    /* width: 100%; */
}

.conference-logo {
    float: left;
    padding-left: 7px;
    color: grey;
    font-size: 13px;
}

.conference-logo-chat {
    float: right;
    padding-right: 7px;
    font-size: 13px;
}

.conference-group-container {
    border: 0.2px solid black;
    width: 100%;
}

.conference-video-container {
    /* border: 0.2px solid black; */
    width: 100%;
}

.conference-chat-container {
    border: 0.2px solid black;
    width: 100%;
}

.conference-container {
    height: 100vh;
}

.conference-server-container {
    padding: 1.2em;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.8em;
}

.conference-server-name {
    float: left;
    font-size: 13px;
}

.conference-server-switch {
    float: right;
    font-size: 12px;
    margin-top: 2px;
}

.conference-group-name {
    font-size: 13px;
    padding: 0.9em;
}

.group-name{
    cursor: pointer;
    float: left;
}

.icon-microphone{
}

.conference-chat {
    padding: 0.1em;
    margin-top: 1em;
}

.icon-profile {
    background-color:#fff;
    border:1px solid black;    
    height:40px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:40px;
}

.profile-container {
    margin: 5px 10px 5px 10px;
}

.video-wrapper {
    width: 90%; 
    height: 9rem; 
    margin: auto;
}

.video-name-wrapper {
    float: right;
    font-size: 13px;
    display: flex;
}

.profile-little {
    background-color:#fff;
    border:1px solid black;    
    height:17px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:17px;
    margin: auto;
}
.profile-little-wrap {
    margin: 5px 10px 5px 10px;
}

.input-icons svg { 
    position: absolute; 
} 
  
.input-icons { 
    width: 100%; 
} 

.icon { 
    padding: 2px;
    margin-left: 3px;
    margin-top: 4px
} 
  
.input-field { 
    width: 100%; 
}

.card-body {
    padding: 0 !important;
}